<template>
    <div class="background">
        <div class="bg-content">
            <div class="row">
                <div class="col-md-12">
                    <br>
                    <div class="menu-bar">
                    <img src="../../assets/icon5.png" alt="" width="40px"> <h2>&nbsp; Dashboard</h2>
                    </div>
                </div>
            </div>
            <span v-if="user_role == 1">
                <div class="row">
                    <div class="card-row">
                        <div class="card bg-user">
                        <span> <h3>Users</h3> &nbsp;&nbsp; <img src="../../assets/icon1.png" alt="" width="40px"> </span> 
                        <span><p class="today-orders">Staff: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">{{ staff_users }}</p></span>
                        <span><p class="today-orders">Members: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">{{ member_users }}</p></span>
                        </div>
                        <div class="card bg-order">
                        <span> <h3>Savings:</h3> &nbsp;&nbsp;<img src="../../assets/icon3.png" alt="" width="40px"></span> 
        
                        <span><p class="today-orders">Total: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">NGN{{total_savings.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</p></span>
                        </div>
                        <div class="card bg-menu">
                            <span> <h3>Pool</h3> &nbsp;&nbsp; <img src="../../assets/icon2.png" alt="" width="40px"> </span> 
                            <span><p class="today-orders">Total: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">NGN{{total_investments.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</p></span>
                        </div>
                    </div>
                </div>
            
                <h6 style="font-size:10px; font-weight:bold">Transaction at a glance</h6>
                <div class="main-content">
                    <div class="view-content">
                        <table id="myTable" class="table table-bordered table-striped">
                            <thead>
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th>Customer</th>
                                    <th>Investments </th>
                                    <th>Tenure</th>
                                    <th>Rate</th>
                                    <th>Amount(NGN)</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in options" :key="index" style="font-size:9px">
                                    <td style="font-weight: 400; width:4%">
                                        {{ index + 1 }}
                                    </td>
                                   
                                    <td style="font-weight: 400; width:20%">
                                        {{ data.firstname }} {{ data.lastname }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.order_id }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ new Date(data.date_ordered).toUTCString() }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.tamount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                    </td>
                                    
                                    <td style="font-weight: 400;">
                                        <span v-if="data.payment_status == 0" class="badge badge-danger"> Processing...</span>
                                        <span v-else-if="data.payment_status == 1" class="badge badge-success">Confirmed</span>
                                        
                                        <span v-if="data.payment_status == 0">
                                            <button style="margin-right:2px; width: auto; height:26px; font-size:9px; background: #110e0edd; border:1px solid #000; color: #fff;"
                                                class="btn btn-success btn-sm" @click="confirmPayment(data.order_id)"><i
                                                    class="fa fa-play"></i> Confrm payment
                                            </button>
                                        </span>
                                    </td>
                                    <td>
                                        <div style="display: flex; flex-direction:row;">
                                            <button style="width: auto; height:26px; font-size:9px; margin-left:2px" class="btn btn-warning btn-sm"
                                                @click="viewOrders(data.order_id)"><i class="fa fa-shopping-cart"
                                                    aria-hidden="true"></i> View order
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="notifications">
                        <div class="notification-header"><i class="fa fa-bell"></i> Withdrawals</div>
                        <div class="notification-content" v-if="new_order != ''">
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in new_order" :key="index">
                                <button type="button" class="close" data-dismiss="alert"  @click="close(data.message)">&times;</button>
                                <strong></strong> {{ data.message }}
                            </div>
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in notifications" :key="index">
                                <button type="button" class="close" data-dismiss="alert" @click="close(data.content)">&times;</button>
                                <strong></strong> {{ data.content }}
                            </div>
                        </div>
                        <div class="notification-content" v-else-if="new_order == ''">
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in notifications" :key="index">
                                <button type="button" class="close" data-dismiss="alert" @click="close(data.content)">&times;</button>
                                <strong></strong> {{ data.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="user_role == 2">
                <div class="row">
                    <div class="card-row">
                        <div class="card bg-user">
                        <span> <h3>Users</h3> &nbsp;&nbsp; <img src="../../assets/icon1.png" alt="" width="40px"> </span> 
                        <span><p class="today-orders">Staff: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">{{ cfirstname }} {{ clastname }}</p></span>
                        <span><p class="today-orders">Members: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">{{ mymember_users }}</p></span>
                        </div>
                        <div class="card bg-order">
                        <span> <h3>Savings:</h3> &nbsp;&nbsp;<img src="../../assets/icon3.png" alt="" width="40px"></span> 
        
                        <span><p class="today-orders">Total: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">NGN{{mytotal_savings.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</p></span>
                        </div>
                        <div class="card bg-menu">
                            <span> <h3>Pool</h3> &nbsp;&nbsp; <img src="../../assets/icon2.png" alt="" width="40px"> </span> 
                            <span><p class="today-orders">Total: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">NGN{{mytotal_investments.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</p></span>
                        </div>
                    </div>
                </div>
            
                <h6 style="font-size:10px; font-weight:bold">Transaction at a glance</h6>
                <div class="main-content">
                    <div class="view-content">
                        <h5>Savings</h5>
                        <table id="myTable" class="table table-bordered table-striped">
                            <thead>
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th>Customer</th>
                                    <th>Investments </th>
                                    <th>Tenure</th>
                                    <th>Rate</th>
                                    <th>Amount(NGN)</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in options" :key="index" style="font-size:9px">
                                    <td style="font-weight: 400; width:4%">
                                        {{ index + 1 }}
                                    </td>
                                    
                                    <td style="font-weight: 400; width:20%">
                                        {{ data.firstname }} {{ data.lastname }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.order_id }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ new Date(data.date_ordered).toUTCString() }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.tamount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                    </td>
                                    
                                    <td style="font-weight: 400;">
                                        <span v-if="data.payment_status == 0" class="badge badge-danger"> Processing...</span>
                                        <span v-else-if="data.payment_status == 1" class="badge badge-success">Confirmed</span>
                                        
                                        <span v-if="data.payment_status == 0">
                                            <button style="margin-right:2px; width: auto; height:26px; font-size:9px; background: #110e0edd; border:1px solid #000; color: #fff;"
                                                class="btn btn-success btn-sm" @click="confirmPayment(data.order_id)"><i
                                                    class="fa fa-play"></i> Confrm payment
                                            </button>
                                        </span>
                                    </td>
                                    <td>
                                        <div style="display: flex; flex-direction:row;">
                                            <button style="width: auto; height:26px; font-size:9px; margin-left:2px" class="btn btn-warning btn-sm"
                                                @click="viewOrders(data.order_id)"><i class="fa fa-shopping-cart"
                                                    aria-hidden="true"></i> View order
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>Pool System</h5>
                        <table id="myTable" class="table table-bordered table-striped">
                            <thead>
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th>Customer</th>
                                    <th>Investments </th>
                                    <th>Tenure</th>
                                    <th>Rate</th>
                                    <th>Amount(NGN)</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in options" :key="index" style="font-size:9px">
                                    <td style="font-weight: 400; width:4%">
                                        {{ index + 1 }}
                                    </td>
                                    
                                    <td style="font-weight: 400; width:20%">
                                        {{ data.firstname }} {{ data.lastname }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.order_id }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ new Date(data.date_ordered).toUTCString() }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.tamount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                    </td>
                                    
                                    <td style="font-weight: 400;">
                                        <span v-if="data.payment_status == 0" class="badge badge-danger"> Processing...</span>
                                        <span v-else-if="data.payment_status == 1" class="badge badge-success">Confirmed</span>
                                        
                                        <span v-if="data.payment_status == 0">
                                            <button style="margin-right:2px; width: auto; height:26px; font-size:9px; background: #110e0edd; border:1px solid #000; color: #fff;"
                                                class="btn btn-success btn-sm" @click="confirmPayment(data.order_id)"><i
                                                    class="fa fa-play"></i> Confrm payment
                                            </button>
                                        </span>
                                    </td>
                                    <td>
                                        <div style="display: flex; flex-direction:row;">
                                            <button style="width: auto; height:26px; font-size:9px; margin-left:2px" class="btn btn-warning btn-sm"
                                                @click="viewOrders(data.order_id)"><i class="fa fa-shopping-cart"
                                                    aria-hidden="true"></i> View order
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="notifications">
                        <div class="notification-header"><i class="fa fa-bell"></i> Withdrawals</div>
                        <div class="notification-content" v-if="new_order != ''">
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in new_order" :key="index">
                                <button type="button" class="close" data-dismiss="alert"  @click="close(data.message)">&times;</button>
                                <strong></strong> {{ data.message }}
                            </div>
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in notifications" :key="index">
                                <button type="button" class="close" data-dismiss="alert" @click="close(data.content)">&times;</button>
                                <strong></strong> {{ data.content }}
                            </div>
                        </div>
                        <div class="notification-content" v-else-if="new_order == ''">
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in notifications" :key="index">
                                <button type="button" class="close" data-dismiss="alert" @click="close(data.content)">&times;</button>
                                <strong></strong> {{ data.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </span>
            <span v-else-if="user_role == 3">
                <div class="wallet">
                    <div class="my-account">
                        <span v-if="payment_status == 1"> 
                            <span class="text-danger" style="font-size:12px">[account active] </span><br>
                            <button class="badge badge-primary" @click="fundWallet()"><i class="fa fa-wallet"></i> fund your wallet </button>
                            <!-- <span>{{ client_bank_name }} </span><br/> -->
                            <!-- <span class="account-number">{{client_account_number}} </span> -->
                        </span>
                        <span v-if="payment_status == 0">
                            <span v-if="pay_with_bank == 0">
                                <button class="btn btn-success btn-sm" @click="activateAccount" v-if="!loading2"> <span style="font-size: 11px">  Activate Account</span>
                                </button>
                                <button class="btn btn-default" type="button" v-if="loading2">
                                   <span style="font-size: 12px"> Generating payment details. Please wait...</span>
                                </button>
                                <br>
                            </span>
                            <span v-if="pay_with_bank == 1"> 
                                <strong>Transfer to:</strong><br/>
                                <div class="acct-d">Beneficiary Name: {{ account_name }}</div>
                                <div class="acct-d">Beneficiary Bank: {{ bank_name }}</div>
                                <div class="acct-d">Beneficiary Account: {{ account_number }}</div>
                                <div class="acct-d">Amount: NGN {{ amount_payment }}</div>
                                <button class="badge badge-success" @click="paymentMade()" v-if="!paymentLoading"> I have paid
                                </button>
                                <button class="badge badge-success" type="button" disabled v-if="paymentLoading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                            </span>
                            <span v-if="pay_with_bank == 2"> 
                                <span style="font-size: 10px; font-weight: bold" class="badge badge-info"> We are confirming your payment. Please be patient...</span>
                            </span>
                        </span>
                    </div>
                    <div class="my-wallet">
                        <p>Wallet: </p>
                        <span class="amount">NGN {{ totalWallet.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }} </span>
                    </div>
                </div>
                <!-- <span v-if="payment_status == 1">
                    <p class="note">Note: You can fund your wallet by transfering into your account number</p>
                </span> -->
                <div class="row">
                    <div class="card-row">
                        <div class="card bg-user">
                        <span> <h3>Saving</h3> &nbsp;&nbsp; <i class="fas fa-cash-register"></i> </span> 
                        <span><p class="today-orders">Savings Amount: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">NGN{{ myTotalSavings.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</p></span>
                        <span v-if="payment_status == 1">
                        <span><p @click="savings_modal = true" class="btn btn-warning btn-sm" style="height: auto; font-size:10px; font-weight: 700;width: auto; color: #fff; background: #000"><i class="fa fa-arrow-right"></i> Start Saving</p>&nbsp;&nbsp; <p class="order-figure badge badge-warning"></p></span>
                        </span>
                        <span v-else-if="payment_status == 0">
                            <span><button @click="savings_modal = true" class="btn btn-warning btn-sm" style="height: auto; font-size:10px; font-weight: 700;width: auto; color: #fff; background: #000" disabled><i class="fa fa-arrow-right"></i> Start Saving</button>&nbsp;&nbsp; <p class="order-figure badge badge-warning"></p></span>
                        </span>
                        
                        </div>

                        <div class="card bg-order">
                        <span> <h3>Pool System:</h3> &nbsp;&nbsp; <i class="fas fa-piggy-bank"></i></span> 
                        <span><p class="today-orders">Total Pool System: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">NGN{{myTotalInvestments.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</p></span>
                            <span v-if="payment_status == 1">
                                <span><p @click="investment_modal = true" class="btn btn-warning btn-sm" style="height: auto; font-size:10px; font-weight: 700;width: auto; color: #fff; background: #000"><i class="fa fa-arrow-right"></i> Choose Package </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning"></p></span>
                            </span>
                            <span v-else-if="payment_status == 0">
                                <span><button @click="investment_modal = true" class="btn btn-warning btn-sm" style="height: auto; font-size:10px; font-weight: 700;width: auto; color: #fff; background: #000" disabled><i class="fa fa-arrow-right"></i> Choose Package </button> &nbsp;&nbsp; <p class="order-figure badge badge-warning"></p></span>
                            </span>
                        </div>
                        <div class="card bg-menu">
                            <span> <h3>Loan</h3> &nbsp;&nbsp; <img src="../../assets/icon2.png" alt="" width="40px"> </span> 
                            <span><p class="today-orders">Total Repayment: </p> &nbsp;&nbsp; <p class="order-figure badge badge-warning">NGN{{myorder_processing.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</p></span>
                        </div>
                    </div>
                </div>
                <h6 style="font-size:12px; font-weight:bold">Transaction at a glance</h6>
                <div class="main-content">
                    <div class="view-content">
                       <p style="font-size:10px; font-weight:bold; line-height: 0px">Pool System</p> 
                        <table id="myTable" class="table table-bordered table-striped">
                            <thead>
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th>Package</th>
                                    <th>Principal</th>
                                    <th>Rate</th>
                                    <th>Duration</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Accumulated Interest </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in totalInvestments" :key="index" style="font-size:9px">
                                    <td style="font-weight: 400; width:4%">
                                        {{ index + 1 }}
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                        {{ data.category_name }} 
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                        NGN {{ data.investment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }} 
                                    </td>
                                    <td style="font-weight: 400; width:5%">
                                        {{ data.rate }}%
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                        {{ data.title }}
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                        {{  new Date(data.date).toDateString() }}
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                        {{ new Date(data.end_date).toDateString()}}
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                       NGN {{ data.accumulatedSum.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="display:flex; flex-direction: row; justify-content: right; font-size:10px; margin-top:-10px"><router-link to="/view-all-fixed-deposits">View All <i class="fa fa-arrow-right"></i></router-link></div>
                        <br>
                        <p style="font-size:10px; font-weight:bold; line-height: 0px">Savings</p>
                        <table id="myTable" class="table table-bordered table-striped">
                            <thead>
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th>Package</th>
                                    <th>Rate</th>
                                    <th>Accumulated Savings </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in totalSavings" :key="index" style="font-size:9px">
                                    <td style="font-weight: 400; width:4%">
                                        {{ index + 1 }}
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                       {{ data.category_name }}
                                    </td>
                                    <td style="font-weight: 400; width:5%">
                                        {{ data.rate }}%
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                        NGN {{ data.totalSum.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                    </td>
                                    <td>
                                        <router-link :to="url+data.savings_type_id">View All <i class="fa fa-arrow-right"></i> </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                    <div class="notifications">
                        <div class="notification-header"><i class="fa fa-bell"></i> Withdrawals</div>
                        <div class="notification-content" v-if="new_order != ''">
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in new_order" :key="index">
                                <button type="button" class="close" data-dismiss="alert"  @click="close(data.message)">&times;</button>
                                <strong></strong> {{ data.message }}
                            </div>
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in notifications" :key="index">
                                <button type="button" class="close" data-dismiss="alert" @click="close(data.content)">&times;</button>
                                <strong></strong> {{ data.content }}
                            </div>
                        </div>
                        <div class="notification-content" v-else-if="new_order == ''">
                            <div class="alert alert-success alert-dismissible" v-for="(data, index) in notifications" :key="index">
                                <button type="button" class="close" data-dismiss="alert" @click="close(data.content)">&times;</button>
                                <strong></strong> {{ data.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </span>

            <!-- Select Investment Modal -->
            <transition name="modal-fade">
                <div v-if="investment_modal">
                    <div class="modal-mask">
                        <div class="modal-wrapper col-md-5" style="margin:auto">
                            <div class="row">
                                <div class="col-md-1">
                                    <a title="Close" class="modal-close-mobile" @click="closeMenu"> <i
                                        class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    <br>
                                </div>
                                <div class="col-md-10">
                                    <div class="row">
                                        <p class="modal-logo">
                                        </p>
                                        <h3 class="text-white">
                                            Choose Package
                                        </h3>
                                        <div class="investment-content">
                                            <select class="form-control investment-input" v-model="investment_package" required @change="fetchDuration">
                                                <option value="">-Select Package-</option>
                                                <option v-for="(data, index) in investments" :key="index" :value="data.category_id"> {{ data.category_name }}</option>
                                            </select>
                                            <select style="margin-top:4px" class="form-control investment-input" v-model="package_duration"  v-if="isDuration" @change="showAmount">
                                                <option value="">-Select Duration-</option>
                                                <option v-for="(data, index) in durations" :key="index" :value="data.id"> {{ data.title }}</option>
                                            </select>
                                            <input style="margin-top:4px" type="text" class="form-control" placeholder="Enter amount to invest" v-model="investment_amount" v-if="isAmount" required @input="checkBalance">
                                            <span class="text-white" v-if="isAmount" style="margin-top:10px; font-size:13px">
                                               Note:- You are investing at the rate of {{ investmentRate }}%
                                            </span>
                                            <div v-if="isAmount">
                                                <span v-if="!balanceEnough">
                                                    <button style="margin-top:15px; cursor:default" class="btn btn-secondary btn-block" disabled>Submit</button>
                                                </span>
                                                <span v-else>
                                                    <button class="form-control btn btn-success btn-block" @click="submitInvestment" v-if="!loading"> Submit
                                                    </button>
                                                    <button class="form-control btn btn-success btn-block" type="button" disabled v-if="loading">
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div>
                                        <a href="#" title="Close" class="modal-close" @click="closeMenu"> <i
                                                class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </transition>
           
            <!-- Select Savings Modal -->
            <transition name="modal-fade">
                <div v-if="savings_modal">
                    <div class="modal-mask2">
                        <div class="modal-wrapper col-md-5" style="margin:auto">
                            <div class="row">
                                <div class="col-md-1">
                                    <a title="Close" class="modal-close-mobile2" @click="closeMenu"> <i
                                            class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    <br>
                                </div>
                                <div class="col-md-10">
                                    <div class="row">
                                        <p class="modal-logo">
                                        </p>
                                        <h3 class="text-white">
                                            Choose Savings
                                        </h3>
                                        <div class="investment-content">
                                            <select class="form-control investment-input" v-model="saving_package" required @change="fetchSavingDuration">
                                                <option value="">-Select Savings Type-</option>
                                                <option v-for="(data, index) in saving_type" :key="index" :value="data.category_id"> {{ data.category_name }}</option>
                                            </select>
                                            <select style="margin-top:4px" class="form-control investment-input" v-model="saving_duration"  v-if="isSavingDuration" @change="showSavingAmount">
                                                <option value="">-Select Frequency-</option>
                                                <option v-for="(data, index) in modes" :key="index" :value="data.saving_mode_id "> {{ data.mode }}</option>
                                            </select>
                                            <input style="margin-top:4px" type="text" class="form-control" placeholder="Enter amount to save" v-model="saving_amount" v-if="isSavingAmount" required @input="checkSavingBalance">
                                            <span class="text-white" v-if="isSavingAmount" style="margin-top:10px; font-size:13px">
                                               Note:- You are saving at the rate of {{ savingsRate }}%
                                            </span>
                                            <div v-if="isSavingAmount">
                                                <span v-if="!balanceSavingEnough">
                                                    <button style="margin-top:15px; cursor:default" class="btn btn-secondary btn-block" disabled>Submit</button>
                                                </span>
                                                <span v-else>
                                                    <button class="form-control btn btn-success btn-block" @click="submitSaving" v-if="!loading"> Submit
                                                    </button>
                                                    <button class="form-control btn btn-success btn-block" type="button" disabled v-if="loading">
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div>
                                        <a href="#" title="Close" class="modal-close2" @click="closeMenu2"> <i
                                                class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </transition>

             <!-- Fund your wallet Modal -->
             <transition name="modal-fade">
                <div v-if="fundwallet_modal">
                    <div class="modal-mask2">
                        <div class="modal-wrapper col-md-5" style="margin:auto">
                            <div class="row">
                                <div class="col-md-1">
                                    <a title="Close" class="modal-close-mobile2" @click="closeMenu3"> <i
                                            class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    <br>
                                </div>
                                <div class="col-md-10">
                                    <div class="row">
                                        <p class="modal-logo">
                                        </p>
                                        
                                        <div class="investment-content">
                                            <div class="account-details">
                                                <p>&nbsp;</p>
                                                <strong>Pay into:</strong><br/>
                                                <div class="acct-c">Beneficiary Name: {{ con_account_name }}</div>
                                                <div class="acct-c">Beneficiary Bank: {{ con_bank_name }}</div>
                                                <div class="acct-c">Beneficiary Account: {{ con_account_number }}</div>
                                                <input style="margin-top:4px" type="text" class="form-control" placeholder="Enter amount" v-model="funding_amount">
                                                <br>
                                                <button class="btn btn-success btn-block form-control" :disabled="validatePayment" @click="fundingMade()" v-if="!fundingLoading"> I have paid
                                                </button>
                                                <button class="btn btn-success btn-block form-control"  type="button" disabled v-if="fundingLoading">
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button>
                                                <p class="wa-chat"><i class="fab fa-whatsapp"></i> <a href="https://wa.me/2349011013113 "> Once payment is completed kindly chat us for immediate confirmation</a> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div>
                                        <a href="#" title="Close" class="modal-close2" @click="closeMenu3"> <i
                                                class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
         <page-loader :is-loading="isLoading"></page-loader>
    </div>    
    </template>
    <script>
    import PageLoader from '../../components/PageLoader.vue'
    import swal from 'sweetalert2';
    import Pusher from 'pusher-js';
   
    export default {
        components: {
        PageLoader,
    },

    data() {

    return {
        
        isLoading: false,
        cfirstname: '',
        clastname: '',
        cusername: '',
        userid:'',
        users: [],
        options: [],
        user_role: '',
        today_orders: 0,
        today_receipts: 0,
        staff_users: 0,
        member_users: 0,
        total_menus: 0,
        monthly_orders: [],
        monthly_receipts: [],
        days: [],
        ndays: [],
        mytoday_receipts: 0,
        total_menus_sold: 0,
        myorder_processing: 0,
        customer_items: [],
        total_amount: '',
        investment_modal: '',
        savings_modal: false,
        fundwallet_modal: false,
        new_order: [],
        notifications: [],
        pusher_app_key: '',
        paymentLoading: false,

        investments: [],
        investment_package: '',
        investment_amount: '',
        durations: [],
        isDuration: false,
        package_duration: '',
        client_account_number: '',
        client_bank_name: '',
        isAmount: false,
        totalDeposit: '',
        totalWithdrawal: '',
        totalWalletAmount: '',
        isActiveInvestment: '',
        balanceEnough: false,
        loading: false,
        totalInvestments: [],
        totalSavings: [],
        saving_type: [],
        saving_package: '',
        saving_amount: '',
        saving_duration: '',
        modes: [],
        isSavingAmount: false,
        isSavingDuration: false,
        balanceSavingEnough: false,
        isActiveSavings: '',

        total_savings: 0,
        total_investments: 0,
        payment_status: '',
        payment_made: '',
        user_email: '',
        paystack_key: '',
        payment_ref: '',
        activation_fee: process.env.VUE_APP_FEE,
        url: 'view-all-savings/',
        pay_with_bank:'',
        loading2: false,
        loading3: false,
        bank_details: [],
        bank_name: '',
        account_name: '',
        account_number: '',
        amount_payment: '',
        ussd_code: '',
        transaction_reference: '',
        transaction_details: '',
        transaction_success: false,
        transaction_success_code: '',
        transaction_payment_status: '',
        isSending: false,
       // duration: 20 * 60 * 1000, // 20 minutes in milliseconds
        interval: 0 * 1000, // 30 seconds in milliseconds
        timer: null, 
        walletBalance: '',
        processBtn: true,
        process: false,
        mymember_users: '',
        mytotal_savings: '',
        mytotal_investments: '',

        registration_account:[ 
            {
                beneficiaryBank : 'Money point',
                beneficiaryName: 'Belmaz MCS Limited',
                beneficiaryAccount: '6380974071',
                amount: '11000'
            }
        ],

        contribution_account:[
            {
                beneficiaryBank : 'FCMB',
                beneficiaryName : 'Belmaz Multipurpose Cooperative Society Limited',
                beneficiaryAccount: '8856274012'
            }
        ],

        con_account_name: '',
        con_bank_name: '',
        con_account_number: '',
        funding_amount: '',
        fundingLoading: false,

        }
        
    },
    
        beforeMount(){
            
            
        },
        mounted() {
            
            this.user_role = localStorage.getItem("user_role");
            this.cfirstname = localStorage.getItem("firstname");
            this.clastname = localStorage.getItem("lastname");
            this.cusername = localStorage.getItem("username");
            this.userid = localStorage.getItem("userid");
            this.pusher_app_key = localStorage.getItem("pusher_app_key");
            
            if (this.user_role == 1) {
                this.staffUsers();
                this.inMemberUsers();
                this.totalMemberInvestments();
                this.totalMemberSavings();
                //this.getWalletBalance('6151486985')
            }
            else if(this.user_role == 2){
                this.MyMemberUsers()
                this.myTotalMemberSavings()
                this.myTotalMemberInvestments()
            }
            else if (this.user_role == 3) {
                this.fetchMembersDetail(this.userid);
                this.fetchMembersWallet(this.userid);
                this.fetchMyAccumulatedInvestmentAmount(this.userid)
                this.fetchMyAccumulatedSavingsAmount(this.userid)
                this.payment_status = localStorage.getItem("payment_status");
                this.payment_made = localStorage.getItem("payment_made");
                this.user_email = localStorage.getItem("user_email");

                if(this.payment_made == 1){
                    this.pay_with_bank = 2
                }
            }
            if (this.user_role == 3) {

                this.fetchAllPackages();
                this.fetchAllSavings();

            }
        
            Pusher.logToConsole = true;
            const pusher = new Pusher(this.pusher_app_key, {
                cluster: 'eu'
            });
            const channel = pusher.subscribe('order');
            channel.bind('notification', data => {
                this.new_order.push(data);
            });
        
                
        },
        created() {
            
        },
        computed: {
            investmentRate() {
            return this.durations.reduce((total, data) =>  data.rate, 0);
            },
            savingsRate() {
            return this.modes.reduce((total, data) =>  data.saving_rate, 0);
            },
            totalWallet(){
                return this.totalWalletAmount;
            },
            savingsDays(){
                return this.modes.reduce((total, data) =>  data.days, 0);
            },
            durationDays(){
                return this.durations.reduce((total, data) =>  data.days, 0);
            },
            myTotalInvestments(){
                return this.totalInvestments.reduce((accumulator, data) =>  accumulator + data.investment_amount, 0);
            },
            myTotalSavings(){
                return this.totalSavings.reduce((accumulator, data) =>  accumulator + data.saving_amount, 0);
            },
            validatePayment() {
            return (
            this.funding_amount === ''
            );
        },
        },
    
        methods: {

            fundWallet(){
                this.con_bank_name = this.contribution_account[0].beneficiaryBank
                this.con_account_name = this.contribution_account[0].beneficiaryName
                this.con_account_number = this.contribution_account[0].beneficiaryAccount

                this.fundwallet_modal = true
            },    
           
            async activateAccount(){
                try{
                    let message = confirm("You're about to activate your account. Activation fee is NGN 11,000.00, Do you want to continue?")
                    if (message == true) {

                            this.loading2 = true

                            // this.response = await this.$http.post('/activate-account', {
                            //     userid: this.userid,
                            //     firstname: this.cfirstname,
                            //     lastname:  this.clastname, 
                            //     email: this.user_email,
                            //     amount: this.activation_fee,
                            // });

                            this.loading2 = false

                      
                            
                            // this.bank_details = this.response.data.transfer_data
                            // this.bank_name = this.bank_details['responseBody']['bankName']
                            // this.account_name = this.bank_details['responseBody']['accountName']
                            // this.account_number = this.bank_details['responseBody']['accountNumber']
                            // this.ussd_code = this.bank_details['responseBody']['ussdPayment']
                            // this.amount_payment = this.bank_details['responseBody']['amount']
                            // this.transaction_reference = this.bank_details['responseBody']['transactionReference']

                            this.bank_name = this.registration_account[0].beneficiaryBank
                            this.account_name = this.registration_account[0].beneficiaryName
                            this.account_number = this.registration_account[0].beneficiaryAccount
                            this.amount_payment = this.registration_account[0].amount
                            this.pay_with_bank = 1
                            //this.paymentMade()
                            return;              
                    
                }               
                }catch (err) {
                    console.log(err);
                }
            },

            // paymentMade(){
            //     try{
            //             this.processBtn = false
            //             this.process = true
            //             this.timer = setInterval(() => {
            //                 this.checkTransactionStatus();
            //             }, this.interval);
                                   
            //     }catch (err) {
            //         console.log(err);
            //     }
            // },
            paymentMade(){
                try{
                    let message = confirm("      You're paying for account activation, are you sure you have made payment?")
                    if (message == true) {
                        this.paymentLoading = true
                        this.updatePaymentMade()
                        this.paymentLoading = false
                    }              
                }catch (err) {
                    console.log(err);
                }
            },

           async fundingMade(){
                try{
                    let message = confirm("You're funding your wallet, are you sure you have made payment?")
                    if (message == true) {
                    
                    this.fundingLoading = true
                    this.response = await this.$http.post('/fund-wallet', {
                        memberid: this.userid,
                        amount: this.funding_amount
                    });  
                    this.fundingLoading = false

                    if(this.response.data.success){
                        swal.fire({
                            icon: 'success',
                            title: 'Payment Submitted',
                            width:450,
                            height:5,
                            text: this.response.data.message,
                        });
                        this.fundwallet_modal = false
                        return;
                    }
                }
                
                }catch (err) {
                    console.log(err);
                }
            },

            async checkTransactionStatus(){
                try{

                            //this.loading3 = true

                            this.response = await this.$http.post('/payment-made', {
                                transaction_reference: this.transaction_reference,
                            });

                            //this.loading3 = false

                        if (!this.response.data.success) {
                            // swal.fire({
                            //     title: 'error',
                            //     width:450,
                            //     height:5,
                            //     text: this.response.data.message,
                            // });
                            // return;

                        } else {
                            
                            this.transaction_details = this.response.data.transaction_data
                            this.transaction_success = this.transaction_details['requestSuccessful']
                            this.transaction_success_code = this.transaction_details['responseCode']
                            this.transaction_payment_status = this.transaction_details['responseBody']['paymentStatus']
                           // console.log(this.transaction_payment_status)
                            if (this.transaction_payment_status == 'PAID') {
                                this.payment_status = 1
                                confirm("    Your payment has been confirmed! Start enjoying our services")
                                this.updatePaymentMade()
                                this.stopSendingRequests()
                            }
                            else {
                              // do nothing
                            }
                            return;              
                    }            
                }catch (err) {
                    console.log(err);
                }
            },

            async updatePaymentMade(){
                try{
                  
                    this.response = await this.$http.post('/update-paymentmade', {
                        userid: this.userid,
                    });  

                    this.pay_with_bank = 2
                
                }catch (err) {
                    console.log(err);
                }
            },

            stopSendingRequests() {
                this.isSending = false;
                clearInterval(this.timer);
            },
            
            showSidebar() {
                var x = document.getElementById("sidebar");
                if (x.style.display === "none") {
                    x.style.display = "block";
                    document.getElementById('btn').style.display='none'
                    document.getElementById('logo').style.display='block'
                } else {
                    x.style.display = "none";
                    document.getElementById('btn').style.display='block'
                    document.getElementById('logo').style.display='none'
                }
            },
        
            profile() {
                document.getElementById("profile").classList.add('profile-menu');
            },

            async staffUsers(){
                try{
                        this.isLoading = true;
                        const response = await this.$http.get('/count-all-staff', )
                        this.staff_users = response.data.staff_count;  
                        this.isLoading = false;  
                    }catch (err) {
                    console.log(err);
                }
            },

            async inMemberUsers(){
                try{
                        this.isLoading = true;
                        const response = await this.$http.get('/count-all-members', )
                        this.member_users = response.data.members_count;  
                        this.isLoading = false;  
                    }catch (err) {
                    console.log(err);
                }
            },

            async MyMemberUsers(){
                try{
                        const response = await this.$http.post('/count-my-members', {
                           userid: this.userid
                        })
                        this.mymember_users = response.data.members_count;    
                    }catch (err) {
                    console.log(err);
                }
            },

            async totalMemberSavings(){
                try{
                        this.isLoading = true;
                        const response = await this.$http.get('/total-savings', )
                        this.total_savings = response.data.total_savings; 
                        this.isLoading = false;   
                    }catch (err) {
                    console.log(err);
                }
            },

            async totalMemberInvestments(){
                try{    this.isLoading = true;
                        const response = await this.$http.get('/total-investments', )
                        this.total_investments = response.data.total_investments;   
                        this.isLoading = false; 
                    }catch (err) {
                    console.log(err);
                }
            },

            async myTotalMemberSavings(){
                try{    this.isLoading = true;
                        const response = await this.$http.post('/mytotal-savings', {
                            userid: this.userid
                        })
                        this.isLoading = false;
                        this.mytotal_savings = response.data.total_savings;    
                        if (this.mytotal_savings == null) {
                            this.mytotal_savings = 0
                        }
                    }catch (err) {
                    console.log(err);
                }
            },

            async myTotalMemberInvestments(){
                try{    
                        this.isLoading = true;
                        const response = await this.$http.post('/mytotal-investments', {
                            userid: this.userid
                        } )
                        this.isLoading = false;
                        this.mytotal_investments = response.data.total_investments;  
                        if (this.mytotal_investments == null) {
                            this.mytotal_investments = 0
                        }  
                    }catch (err) {
                    console.log(err);
                }
            },

           
            closeMenu() {
                this.investment_modal = false
                this.isDuration = false
                this.investment_package = ''
                this.isAmount = false
                this.investment_amount = ""
            },

            closeMenu2() {
                this.savings_modal = false
                this.isDuration = false
                this.investment_package = ''
                this.isAmount = false
                this.investment_amount = ""
            },

            closeMenu3() {
                this.fundwallet_modal = false
            },

            async fetchTodayNotifications() {
                try {
                    const response = await this.$http.get('/fetch-today-notifications',)
                    this.notifications = response.data.notifications;

                } catch (err) {
                    console.log(err);
                }
            },

            async close(notification_id){
                try{
                        await this.$http.post('/close-notification', {
                            id: notification_id,
                        })               
                    
                    }catch (err) {
                    console.log(err);
                }
            },

            async fetchAllPackages() {
                    try {
                        this.isLoading = true
                        const response = await this.$http.get('/fetch-investment-package', )
                        this.isLoading = false
                        this.investments = response.data.categories;
        
                    } catch (err) {
                        console.log(err);
                }
            },

            async fetchDuration() {
                try {
                        if (this.investment_package =='') {
                            this.package_duration = ''
                            this.isDuration = false
                            this.isAmount = false
                        }else{
                            this.isLoading = true
                            this.isDuration = true
                            this.package_duration = ''
                            const response = await this.$http.post('/fetch-investment-duration-byid', {
                                member_id: this.userid,
                                category_id: this.investment_package
                            })
                            this.isLoading = false
                            this.durations = response.data.durations;
                            this.isActiveInvestment = response.data.activeInvestment;
                            if (this.isActiveInvestment) {
                                swal.fire({
                                    icon: 'error',
                                    width:450,
                                    height:5,
                                    title: "Error",
                                    text: 'This package is active and running. You cannot invest on it',
                                });
                                this.isDuration = false
                                this.investment_package = ''
                                this.isAmount = false
                                return;
                            }
                        }
                    } catch (err) {
                    console.log(err);
                }
            },

            async fetchAllSavings() {
                    try {
                        this.isLoading = true;
                        const response = await this.$http.get('/fetch-savings-groupby-type', )
                        this.isLoading = false;
                        this.saving_type = response.data.categories;
        
                    } catch (err) {
                        console.log(err);
                    }
            },

            async fetchSavingDuration() {
                try {
                        if (this.saving_package =='') {
                            this.saving_duration = ''
                            this.isSavingDuration = false
                            this.isSavingAmount = false
                        }else{
                            this.isLoading = true
                            this.isSavingDuration = true
                            this.saving_duration = ''
                            const response = await this.$http.post('/fetch-saving-duration-byid', {
                                category_id: this.saving_package
                            })
                            this.isLoading = false
                            this.modes = response.data.durations;
                            this.isActiveSavings = response.data.activeSavings;
                            if (this.isActiveSavings) {
                                swal.fire({
                                    icon: 'error',
                                    width:450,
                                    height:5,
                                    title: "Error",
                                    text: 'This investment package is active and running. You cannot invest on it',
                                });
                                this.isSavingDuration = false
                                this.saving_package = ''
                                this.isSavingAmount = false
                                return;
                            }
                        }
                    } catch (err) {
                    console.log(err);
                }
            },

            showAmount(){
                if (this.package_duration =='') {
                        this.isAmount = false
                }else{
                    this.isAmount = true
                }
            },

            showSavingAmount(){
                if (this.saving_duration =='') {
                        this.isSavingAmount = false
                }else{
                    this.isSavingAmount = true
                }
            },

            async fetchMembersDetail(member_id) {
                try {
                    this.isLoading = true
                    const response = await this.$http.post('/fetch-member-accountno', {
                        memberid: member_id
                    })
                    this.isLoading = false
                        this.membersList = response.data.members;
                        this.client_account_number = this.membersList[0].account_number
                        this.client_bank_name = this.membersList[0].bank_name
                        
                } catch (err) {
                    console.log(err);
                }
            },

            async fetchMembersWallet(member_id){
                try {
                        this.isLoading = true
                        const response = await this.$http.post('/fetch-member-wallet', {
                            memberid: member_id
                        })
                        this.isLoading = false
                        this.totalDeposit = response.data.members[0].totalDeposit;
                        this.totalWithdrawal = response.data.members[0].totalWithdrawal;
                        this.totalWalletAmount = this.totalDeposit - this.totalWithdrawal
                        
                } catch (err) {
                    console.log(err);
                }
            },

            async checkBalance(){
                try {
                        const response = await this.$http.post('/check-wallet-balance', {
                            memberid: this.userid,
                            amount: this.investment_amount
                        })
                        if (!response.data.success) {
                            swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: response.data.message,
                            });
                            this.investment_amount = ''
                            this.balanceEnough = false
                            return;
                        }else{
                            this.balanceEnough = true;
                            return;
                        }
                } catch (err) {
                    console.log(err);
                }
            },

            async checkSavingBalance(){
                try {
                        const response = await this.$http.post('/check-wallet-balance', {
                            memberid: this.userid,
                            amount: this.saving_amount
                        })
                        if (!response.data.success) {
                            swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: response.data.message,
                            });
                            this.saving_amount = ''
                            this.balanceSavingEnough = false
                            return;
                        }else{
                            this.balanceSavingEnough = true;
                            return;
                        }
                } catch (err) {
                    console.log(err);
                }
            },

            async submitInvestment(){
                try {
                        
                            if(!this.investment_package){
                                swal.fire({
                                    icon: 'warning',
                                    title: 'Error...',
                                    width:450,
                                    height:5,
                                    text: 'Please select package ',
                                })
                                return;
                            }
                            if (!this.package_duration ){
                                swal.fire({
                                    icon: 'warning',
                                    title: 'Error...',
                                    width:450,
                                    height:5,
                                    text: 'Please select duration ',
                                })
                                return;
                            }
                            if (!this.investment_amount ){
                            
                                swal.fire({
                                    icon: 'warning',
                                    title: 'Error...',
                                    width:450,
                                    height:5,
                                    text: 'Please enter amount to invest',
                                })
                                return;
                            }
                            this.loading = true;
                            const response = await this.$http.post('/add-investment', {
                                memberid: this.userid,
                                categoryid: this.investment_package,
                                durationid: this.package_duration,
                                amount: this.investment_amount,
                                rate: this.investmentRate,
                                days: this.durationDays
                            })
                            this.loading = false;
                            if (!response.data.success) {
                                swal.fire({
                                    icon: 'error',
                                    width:450,
                                    height:5,
                                    title: "Error",
                                    text: response.data.message,
                                });
                                return;
                            }else{
                                swal.fire({
                                    icon: 'success',
                                    width:450,
                                    height:5,
                                    title: "Success",
                                    text: response.data.message,
                                });

                                this.investment_modal = false
                                this.isDuration = false
                                this.investment_package = ''
                                this.isAmount = false
                                this.investment_amount = ""
                                this.fetchMyAccumulatedInvestmentAmount(this.userid)
                                this.fetchMembersWallet(this.userid);
                                return;
                            }
                    } catch (err) {
                    console.log(err);
                }
            },

            async submitSaving(){
                try {
                        
                        if(!this.saving_package){
                            swal.fire({
                                icon: 'warning',
                                title: 'Error...',
                                width:450,
                                height:5,
                                text: 'Please select saving type ',
                            })
                            return;
                        }
                        if (!this.saving_duration ){
                            swal.fire({
                                icon: 'warning',
                                title: 'Error...',
                                width:450,
                                height:5,
                                text: 'Please select frequency ',
                            })
                            return;
                        }
                        if (!this.saving_amount ){
                        
                            swal.fire({
                                icon: 'warning',
                                title: 'Error...',
                                width:450,
                                height:5,
                                text: 'Please enter amount to save',
                            })
                            return;
                        }
                        this.loading = true;
                        const response = await this.$http.post('/add-savings', {
                            memberid: this.userid,
                            categoryid: this.saving_package,
                            durationid: this.saving_duration,
                            amount: this.saving_amount,
                            rate: this.savingsRate,
                            days: this.savingsDays
                        })
                        this.loading = false;
                        if (!response.data.success) {
                            swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: response.data.message,
                            });
                            return;
                        }else{
                            swal.fire({
                                icon: 'success',
                                width:450,
                                height:5,
                                title: "Success",
                                text: response.data.message,
                            });

                            this.savings_modal = false
                            this.isSavingDuration = false
                            this.saving_package = ''
                            this.isSavingAmount = false
                            this.saving_amount = ""
                            this.fetchMyAccumulatedSavingsAmount(this.userid)
                            this.fetchMembersWallet(this.userid);
                            return;
                        }
                } catch (err) {
                console.log(err);
            }
            },

            async fetchMyAccumulatedInvestmentAmount(member_id){
                try {   
                         this.isLoading = true
                        const response = await this.$http.post('/fetch-accumulated-investment', {
                            memberid: member_id
                        })
                        this.isLoading = false
                        this.totalInvestments = response.data.interest;
                        
                } catch (err) {
                    console.log(err);
                }
            },

            async fetchMyAccumulatedSavingsAmount(member_id){
                try {
                        this.isLoading = true
                        const response = await this.$http.post('/fetch-accumulated-savings', {
                            memberid: member_id
                        })
                        this.isLoading = false
                        this.totalSavings = response.data.interest;
                        
                } catch (err) {
                    console.log(err);
                }
            },

            async getWalletBalance(user_account_number){
                try {
                        const response = await this.$http.post('/get-wallet-balance', {
                            account_number: user_account_number
                        })

                        this.walletBalance = response.data.balance;
                        
                } catch (err) {
                    console.log(err);
                }
            },
        },
        
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

    .modal-fade-enter-active, .modal-fade-leave-active {
        transition: opacity 0.1s;
    }
    .modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
    opacity: 0.1;
    }
    .wa-chat{
        font-size: 12px;
    }
    .background{
        display: flex;
        flex-direction: row;
        justify-content: center;
        background: #ccc;
        border-radius: 10px;
    }
    .acct-d{
        font-size: 12px;
        color: #1a1111
    }
    .bg-content{
        padding: 10px;
        padding-right: 20px;
        width: 100%;
        
    }
    .account-details{
        font-size:13px !important;
        color: #fff !important;
    }
    .main-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .view-content {
        width: 70%;
        overflow-x: auto;
        overflow-y: auto;
        height: 320px;
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);
        padding: 10px;
    }

    .notifications{
        width: 30%;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        color: #fff;
        font-size:8px;
        font-weight: 650;
        font-family: Mulish;
        overflow-x: auto;
        overflow-y: auto;  
        height: 320px;
        background: #fff;
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);
        
        .notification-header {
            width: 100%;
            background: #000;
            padding: 5px;
            border-radius:2px;
        }
        .notification-content{
            padding:6px;
        }

    }
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #2b0c178c;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }

    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: #fff;
        }
    }
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: #FFF;
        }
    
        display: none;
    }

    .modal-mask2 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #2b0c178c;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }
    .modal-close2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: #fff;
        }
    }
    .modal-close-mobile2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: #FFF;
        }
    
        display: none;
    }

    .chart {
        display:flex; 
        flex-direction: row; 
        justify-content: center; 
        background: #fff; 
        border-radius: 5px; 
        width: 95%;
        margin-left: 20px;
    }
    .order-report{
        p{
            margin-top: 10px;
            margin-bottom: 5px;
            text-align: center;
            font-size: 12px;
            font-weight: 800;
        }
    }
    .receipts-report{
        p{
            margin-top: 10px;
            margin-bottom: 5px;
            text-align: center;
            font-size: 12px;
            font-weight: 800;
        }
    }
    .content{
        h2{
            color: #000;
            font-weight: 650;
            font-family: Mulish;
        }
    }

    .menu-bar{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        box-sizing: border-box;
        height: 50px;
        background: #000;
        padding: 10px;
        border-radius: 4px;
    }
    .card-row{
        display:flex;
        flex-direction: row;
        justify-content:space-evenly;
        
    }
    .wallet{
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
    }
    .my-account{
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        
        p {
            margin-top: 10px;
            line-height: 1px;
            font-weight: 700;
            font-size: 12px;
        }
        .account-number{
            font-size: 16px;
            color:#704141;
            font-weight: 800;
            font-family: Mulish;
            line-height: 1px;
            margin-bottom: 10px;
        }
    }
    .my-wallet{
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        
        p {
            margin-top: 5px;
            line-height: 1px;
            font-weight: 700;
            font-size: 12px;
        }
        .amount{
            font-size: 16px;
            color:#803f3f;
            font-weight: 800;
            font-family: Mulish;
            line-height: 1px;
            margin-bottom: 10px;
            text-align: right;
        }
    }
    .note{
            font-size: 10px !important;
            color:#000;
            font-family: Mulish;
            margin-bottom: -2px;
        }
    .card{
        margin: 6px;
        width: 100%;
        height: 85%;
        padding: 10px;
        border: 1px solid #21182f;
        color:#000;
        box-shadow: 2px 1px 8px 1px #888888;

        h3 {
            margin-top: 4px;
            font-size: 16px;
            font-weight: 500;
            color:#000;
            font-weight: 650;
            font-family: Mulish;
        }
        .today-orders{
            margin-top: 2px;
            font-family: Mulish;
            font-size: 10px;
            line-height: 1px;
        }
        .order-figure {
            margin-top: 2px;
            font-family: Mulish;
            font-size: 10px;
            line-height: 1px;
            font-weight: 800;
            text-align: left;
            height: 10px;
        }

        span{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: Mulish;
            .fa-users{
                font-size: 20px;
            }
            .fa-user{
                font-size: 20px;
            }
        }
        .count{
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            font-family: Mulish;
        }
        .count_mode{
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            padding-left: 30px;
        }
    }

    .background{
        padding:20px
    }

    .bg-user{
        background: #3afbdb28;
       // opacity: 0.8;
    }
    .bg-order{
        background: #b73afb28;
        //opacity: 0.8;
    }
    .bg-menu{
        background: #abfb3a28;
        //opacity: 0.8;
    }

    h2 {
        color: #FFF;
        font-size:18px;
        font-family: Mulish;
    }
    table > thead {
        font-size: 12px;
        font-family: Mulish;
    }
    table > tbody {
        font-size: 11px;
        font-family: Mulish;
    }

    @media screen and (max-width: 700px) {

        .modal-mask {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
        
            &>div {
                width: 80%;
                height: 40%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #2b0c178c;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
        
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
        
            }
        
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
        
            span {
                margin-bottom: 20px;
            }
        
        }
        .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: #FFF;
            }

            display: none;
        }
        .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: #FFF;
            }
    
            display: block;
        }
        .modal-mask2 {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
        
            &>div {
                width: 80%;
                height: 40%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #2b0c178c;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
        
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
        
            }
        
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
        
            span {
                margin-bottom: 20px;
            }
        
        }
        .modal-close2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: #FFF;
            }

            display: none;
        }
        .modal-close-mobile2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: #FFF;
            }
    
            display: block;
        }
        .chart {
            display:flex; 
            flex-direction: column; 
            justify-content: center; 
            background: #fff; 
            border-radius: 5px; 
            width: 90%;
            align-items: center;
            font-size: 8px;
            display: none;
        }
        .menu-bar{
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            box-sizing: border-box;
            height: 50px;
            background: #000;
            padding: 10px;
            border-radius: 4px;
        }
        .background{
            height: 130vh;
            background:#cccccc46;
            padding-right: 20px;
        }
        .main-content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .view-content {
            overflow-x: auto;
            overflow-y: auto;
            height: 230px;
            width: 96%;
        }
        .notifications{
            margin-top: 10px;
            width: 90%;
            display: flex;
            flex-direction: column;
            color: #fff;
            font-size:8px;
            font-weight: 650;
            font-family: Mulish;
            overflow-x: auto;
            overflow-y: auto;  
            height: 200px;
            background: #fff;
            box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);
            
            .notification-header {
                width: 100%;
                background: #000;
                padding: 5px;
                border-radius:2px;
            }
            .notification-content{
                padding:6px;
            }
    
        }
        .my-account{
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            
            p {
                margin-top: 12px;
                //line-height: 1px;
                font-weight: 700;
                font-size: 12px;
            }
            .account-number{
                font-size: 10px;
                color:#704141;
                font-weight: 800;
                font-family: Mulish;
                margin-bottom: 10px;
            }
        }
        .my-wallet{
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            
            p {
                margin-top: 5px;
                //line-height: 1px;
                font-weight: 700;
                font-size: 10px;
            }
            .amount{
                font-size: 10px;
                color:#803f3f;
                font-weight: 800;
                font-family: Mulish;
                line-height: 1px;
                margin-bottom: 10px;
                text-align: right;
            }
        }
        .note{
                font-size: 8px;
                color:#000;
                font-family: Mulish;
                margin-bottom: -2px;
            }
        .card-row{
            display:flex;
            flex-direction: column;
            justify-content:space-evenly;
        }
        .card{
            margin: 6px;
            width: 95%;
            height: 120px;
            padding: 10px;
            border: 1px solid #21182f;
    
            h3 {
                margin-top: 4px;
                font-size: 15px;
                font-weight: 300;
                color:#000;
                font-family: Mulish;
            }
    
            span{
                display: flex;
                flex-direction: row;
                .fa-users{
                    font-size: 20px;
                }
                .fa-user{
                    font-size: 20px;
                }
            }
            .count{
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-size: 20px;
                font-weight: 500;
                font-family: Mulish;
            }
            .count_mode{
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 13px;
                font-weight: 200;
                padding-left: 30px;
                font-family: Mulish;
            }
        }
        h2 {
            color: #FFF;
            font-size: 20px;
            font-family: Mulish;
        }

    }

    </style>